<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Tiempo real" :exportInfo="currItems" :dates="[dateSearch, dateSearch]">
      <template v-slot:buttons>
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-row>
        <v-col cols="12" md="3" class="pl-8">
          <Date-Picker v-model="dateSearch" label="Fecha" offYear />
        </v-col>
      </v-row>
      <v-data-table :headers="headers" :items="items" :loading="loading" :search="search" @current-items="getFiltered"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="itemsPerPage" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.nombre`]="{ item }">
          {{ item.nombre + " " + item.apellido_paterno + " " + item.apellido_materno }}
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, BasicAlert, TableContainer, BasicBtn, DatePicker } from "@/components";
import moment from "moment";

export default {
  name: "real-time",
  components: {
    Loader,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DatePicker,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    timeInterval: null,
    dateSearch: null,
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    headers: [
      { text: "Numero de empleado", align: "left", sortable: true, value: "no_empleado" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre" },
      { text: "", align: " d-none", sortable: true, value: "nombre_completo" },
      { text: "Evento", align: "left", sortable: true, value: "evento" },
      { text: "Departamento", align: "left", sortable: true, value: "departamento" },
      { text: "Fecha", align: "left", sortable: false, value: "fecha" },
      { text: "Retraso (En minutos)", align: "left", sortable: false, value: "tiempo_retardo" },
    ],
    items: [],
    currItems: [],
    itemsPerPage: 20
  }),
  watch: {
    dateSearch(val) {
      if (val == this.today) {
        this.refresh();
        const that = this;
        this.timeInterval = setInterval(function () {
          that.refresh();
        }, 60000);
      } else {
        clearInterval(this.timeInterval);
        this.refresh();
      }
    },
    search(val) {
      this.itemsPerPage = (val) ? -1 : 20
    }
  },
  mounted() {
    this.dateSearch = this.today;
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
  },
  methods: {
    refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.eventFind + "?fecha=" + this.dateSearch)
        .then((response) => {
          this.items = response.data.data;
          for (let i = 0; i < this.items.length; i++) {
            this.items[i].fecha = this.dateFormat(this.items[i].fecha);
            this.items[i].nombre_completo = this.items[i].nombre + ' ' + this.items[i].apellido_paterno + ' ' + this.items[i].apellido_materno
            if (this.items[i].evento === 'SALIDA' && this.items[i].tiempo_retardo < 0) {
              this.items[i].tiempo_retardo = this.items[i].tiempo_retardo * -1;
            }
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Registros del día " + this.dateSearch + " obtenidos correctamente.";
        });
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY h:mm:ss A");
    },
    getFiltered(values) {
      this.currItems = values
    }
  },
};
</script>

<style scoped>
</style>
